<template>
  <div style="margin-left: auto;
    margin-right: auto;padding-top:12px;padding-bottom:12px;">
    <v-row justify="center" no-gutters>
      <span style="margin-right:38px"> Copyright©2021 城湾科技 All Rights Reserves. </span>
      <span style="margin-right:16px"> 隐私政策 </span>
      <span style="margin-right:14px">|</span>
      <span>法律声明</span>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>