import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import home from '@/views/home/home.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		component: Home,
		redirect: () => {
			return '/home'
		}
	},
	{
		path: '/home',
		component: home,
		meta: {name: '首页'}
	},
	{
		path: '/About-Us',
		component: () => import('@/views/About-Us/About-Us.vue'),
		meta: {name: '关于我们'},
		redirect: () => {
			return '/home'
		},
	},
	{
		path: '/product-recommend',
		component: () => import('@/views/product-recommend/product-recommend.vue'),
		meta: {name: '产品介绍'}
	},
	{
		path: '/customer-case',
		component: () => import('@/views/customer-case/customer-case.vue'),
		meta: {name: '客户案例'}
	},
	{
		path: '/detail-page/:id',
		component: () => import('@/views/detail-page/detail-page.vue'),
		meta: {name: '详情页'}
	},
	{
		path: '/cooperative-partner',
		component: () => import('@/views/cooperative-partner/cooperative-partner'),
		meta: {name: '合作伙伴'},
	},
	{
		path: '/join-us',
		meta: {name: '加入我们'},
		component: () => import('@/views/join-us/join-us.vue'),
	},
	{
		path: '/error/:id',
		component: () => import('@/views/error/error.vue'),
		meta: {name: '缺省页'}
	},
	{
		path: '/search',
		component: () => import('@/views/search/search.vue'),
		meta: {name: '搜索结果页面'}
	},
	{
		path: '*',
		name: '404',
		redirect: () => {
			return '/error/404'
		}
	},
]


const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

const VueRouterPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(to) {
	return VueRouterPush.call(this, to).catch(err => err);
}

export default router
