<template>
  <div style="position: inherit;
        width: 600px;
        top: 0px;
        right: 200px;">
    <div
      style="
        font-size: 56px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #333333;
        line-height: 74px;
      "
    >
      加入我们
    </div>
    <div
      style="
        height: 48px;
        font-size: 36px;
        font-family: MicrosoftYaHeiLight;
        color: #333333;
        line-height: 48px;
      "
    >
      JOIN US
    </div>
    <div style="margin-top: 40px">
      <span
        style="
          font-size: 20px;
          font-family: MicrosoftYaHei;
          color: #333333;
          line-height: 26px;
        "
      >
       <v-icon color="#333333FF">iconfont icon-youxiangmianxing</v-icon> E-mail：chengwankeji@163.com
      </span>
    </div>
    <div style="margin-top: 40px">
        
      <span
        style="
          font-size: 20px;
          font-family: MicrosoftYaHei;
          color: #333333;
          line-height: 26px;
        "
      >
        <v-icon color="#333333FF">iconfont icon-dianhuamianxing</v-icon> Tel：0779-3220011
      </span>
    </div>
    <div style="margin-top: 40px">
      <span
        style="
          font-size: 20px;
          font-family: MicrosoftYaHei;
          color: #333333;
          line-height: 26px;
        "
      >
      
        <v-icon color="#333333FF">iconfont icon-dizhimianxing</v-icon> Add：广西北海市四川路299号高新科技园区
      </span>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>