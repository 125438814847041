export default [
    {
        headline: "AI智能路灯",
        text: [
            "公园AI安全及服务机器人，拥有智能监控、感应照明、信息发布等功能",
            "智能监控 - 集成摄像头实现安防监控、体温监测、口罩识别监测、越界落水监测、人群聚集监测、人流量监测；",
            "感应照明 - 行人靠近，灯光变亮；行人离开，灯光变暗；",
            "信息发布 - 行为制约（戴口罩等）、导览播报、信息广播等语音实时播报。"
        ],
        dateTime: '2020-09-01 12:00:00'
    },
    {
        headline: "数景云平台",
        text: [
            "数字化景区管理云平台（简称数景中台）主要功能是接收渠道订单，分发给景区本地客房、餐饮、门票等子系统。通过综合预定页面，对客房、餐饮、票务、自定义套餐预定订单进行管理；预付款信息查询及预付款退款业务；统计订单信息并形成报表；客户档案管理及历史订单查询；渠道信息设置、渠道锁房设置等功能。"
        ],
        dateTime: '2021-06-14 12:13:00'
    },
    {
        headline: "智能化设备管理平台",
        text: [
            "随着5G、新基建时代的到来，人们希望打造一个AI沉浸式服务体验的未来科技园，管理中心平台是公园大脑，AI机器⼈家族是服务终端，为市民打造智慧健康服务、智慧科普服务、智慧安全服务、智慧管理于⼀身的未来科技体验园。",
            "智能化设备管理平台实现与云享小镇等平台的数据共享。提升惠民实用服务质量，通过智能方式（例如AI智能路灯、AI智能垃圾桶、AI管家等）给公园服务提质，科技设备惠民实用，户外耐用且维护成本不高，让人民群众在这里获得幸福感。解决未来无人化管理问题，通过智能的方式降低人工成本、降低水耗电耗，提升公园的生态效益及经济效益。"
        ],
        dateTime: '2021-06-18 13:11:00'
    },
    {
        headline: "乌镇人民公园",
        text: [
            "2020世界互联网大会前夕，乌镇把5G技术已经运用到了小镇的各个角落。有着67年历史的乌镇人民公园，在5G+AI、5G+AR等技术的赋能下，焕然一新，小桥流水人家的古典美景，叠加未来智能社会的超现代主义场景，别具一格。在乌镇5G未来城镇运营中心，可以实时查看乌镇各项运行指标：大到城镇产业结构分析、大气水质监测、游客人流量数据，小到一处道路积水、一个井盖损坏告警……都在一张巨幅大屏上一览无余。",
            "2019年5月17日，浙江移动与乌镇镇政府签订5G示范小镇建设战略合作协议，共同启动了“乌镇5G示范小镇”建设。如今，全国首个5G示范小镇脱颖而出，乌镇正因为5G而变得更智慧，更具科技感。乌镇人民公园作为乌镇互联网大会配套工程，依托5G高速网络环境，通过AI赋能物联网传感设备，实现了绿色雨林生态与智慧互动体验的完美结合。随着大会即将召开，公园成了新的网红打卡地，参观体验者络绎不绝。"
        ],
        dateTime: '2020-09-04 12:09:06'
    },
    // {
    //     headline: "乌镇隆源路街区",
    //     text: [
    //         "乌镇属于全国重点示范小镇，经常有全国各地的政府接待，加上土地场馆等资源较多，这时候镇区产业就需要聚集，互联网红利需要转化，品牌矩阵需再布局，旅游人群需加强利用等。我们要打造全球首个深度融合景区运营管理体系，以智能视觉识别为主要驱动力进而带动上下游产业落地的互联网小镇。",
    //         "我们的目标是让镇区的品牌年轻化，吸引新生代游客，丰富内容体验，增加二销，形成产业矩阵，拉动品牌与经济增长，做实“互联网小镇”之名，树立小镇级政府形象标杆；打造街区改造标杆项目，塑造集文化科技艺术与虚拟游览体验⼀体的新型城镇生活方式与商业管理模式，打造全球首个智慧化运营的沉浸式样板街区。"
    //     ]
    // }
]