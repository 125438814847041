<template>
  <v-app>
    <v-app-bar
      height="120"
      style="z-index: 999;transform: none;"
      flat
      :class="{ whitebeijin: white, roof: !white }"
      fixed
      :value="barHover"
      @mouseout="mss"
    >
      <navigation v-on:callback="(res) => (white = res)" :color="!white" />
    </v-app-bar>
    <div
      style="height: 120px; position: fixed; width: 100%; z-index: 99;"
      v-show="!barHover"
      @mouseover="maa"
    >
    </div>
    <v-main>
      <router-view />
    </v-main>

    <v-footer
      color="footerColor white--text"
      fixed
      padless
      v-show="footerHover"
      transition="scale-transition"
    >
      <Footer />
    </v-footer>
  </v-app>
</template>

<script>
import navigation from "@/components/navigation/navigation.vue";
import Footer from "./components/footer/footer.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "App",
  components: {
    navigation,
    Footer,
  },
  computed: {
    ...mapGetters(["barHover", "footerHover"]),
  },
  data: () => ({
    //
    white: false,
    bj: true,
  }),
  methods: {
    ...mapMutations(["topHoverFun"]),
    mss(e) {
      if (e.clientY >= 120) {
        this.topHoverFun(false);
        this.bj = false;
        if (!this.bj) {
          this.bj = true;
        }
      }
    },
    maa() {
      if (this.bj) {
        this.topHoverFun(true);
      }
    },
  },
  mounted() {

  },
  watch: {
    footerHover: function () {
    },
    "$store.state.navigationColor": {
      handler: function (v) {
        this.white = !v;
      },
    },
  },
};
</script>
