import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        topHover: true,
        bottomHover:false,
        skip: "",
        navigationColor: true
    },
    getters: {
        barHover: state => {
            return state.topHover;
        },
        footerHover: state => {
            return state.bottomHover;
        },
        skipget: state => {
            return state.skip;
        }
    },
    mutations: {
         topHoverFun(state, n) {
             state.topHover = n;
         },
         bottomHoverFun(state, n) {
             state.bottomHover = n;
         },
         skipFun(state,n) {
            state.skip = n;
         },
         navigationFun(state, n) {
            state.navigationColor = n;
         }
    }
});

export default store;