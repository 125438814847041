<template>
  <div>
    <div
      class="fullBg"
      :style="{ backgroundImage: `url(${url})` }"
      v-for="(url, index) in imgArr"
      :key="index"
      :class="{ fullBg2: activate[index] }"
    ></div>
    <v-overlay>
      <div
        class="jzbj-case"
        @mouseover="shiftIn"
        ref="box"
        :style="{ width: width + 'px' }"
        style="overflow: hidden"
      >
        <div
          :style="{
            transform: `translate3d(${left}px,0,0)`,
            width: contentWidth + 'px',
            height: '556px',
          }"
          class="excursion"
        >
          <v-hover
            v-slot="{ hover }"
            v-for="(url, index) in imgArr"
            :key="index"
            class="hb"
          >
            <div
              style="float: left; cursor: pointer"
              @mouseover="shiftIn2(index)"
            >
              <div
                class=""
                style="
                  background-size: 100% 100%;
                  background-repeat: no-repeat;
                  margin-right: 80px;
                  width: 560px;
                  height: 376px;
                "
                :style="{ backgroundImage: `url(${url})` }"
                @click="route(index)"
              ></div>
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex transition-fast-in-fast-out v-card--reveal white--text kdu"
                >
                  <div class="jklo">
                    <h1
                      style="
                        width: 252px;
                        height: 47px;
                        font-size: 36px;
                        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                        font-weight: bold;
                        color: #fff;
                        line-height: 47px;
                      "
                    >
                      {{ text[index].title }}
                    </h1>
                    <p>
                      {{ text[index].content }}
                    </p>
                  </div>
                </div>
              </v-expand-transition>
            </div>
          </v-hover>
        </div>
      </div>
    </v-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgArr: [
        require("@/assets/img/customer/wzrmgy.jpg"),
        // require("@/assets/img/customer/wzlyljq.jpg"),
      ],
      activate: [false, false],
      imgUrl: "",
      index: 0,
      left: 0,
      right: 0,
      goal: "",
      goal2: "",
      text: [
        {
          title: "乌镇人民公园",
          content:
            "2020世界互联网大会前夕，乌镇把5G技术已经运用到了小镇的各个角落。有着67年历史的乌镇人民公园，在5G+AI、5G+AR等技术的赋能下，焕然一新…",
        },
        // {
        //   title: "乌镇隆源路街区",
        //   content:
        //     "乌镇每年有稳定的财政补贴，属于全国重点示范⼩镇，经常有全国各地的政府接待，加上⼟地场馆等资源较多，这时候镇区产业就需要聚集，互联⽹红利需要转化…",
        // },
      ],
      j: false,
      bkk: {
        opacity: 1,
      },
      moo: true,
      width: "",
      height: "",
      contentWidth: "",
      okm: "",
      centre: "",
      maxLeft: "",
      maxRight: "",
    };
  },
  methods: {
    shiftIn(e) {
      let box = this.$refs.box.getBoundingClientRect();
      if (this.goal2 != this.goal) {
        this.moo = false;
        this.goal2 = this.goal;

        if (e.clientX - box.left < (box.right - box.left) / 2) {
          if (this.left + 560 <= this.maxLeft) {
            this.left += 560;
          }
        } else {
          console.log(this.left - 560);
          if (this.left - 560 >= this.maxRight) {
            this.left -= 560;
          }
        }
        this.moo = true;
      }
    },
    shiftIn2(i) {
      this.goal = i;
      this.imgUrl = this.imgArr[i];
      this.activate[this.okm] = false;
      this.activate[i] = true;
      this.okm = i;
    },
    win() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      this.contentWidth = this.imgArr.length * 640;
      this.left = this.width / 2 - 320;
    },
    distance() {
      let len = this.imgArr.length;
      let num = len % 2 == 0 ? "偶数" : "奇数";
      if (num == "偶数") {
        len = len / 2;
        this.maxLeft = len * 560 + this.left - 560;
        this.maxRight = this.left - len * 560;
      } else {
        len = len / 2 + 0.5;
        this.maxLeft = len * 560 + this.left - 560 * len;
        this.maxRight = this.left - len * 560;
      }
    },
    route(i) {
      if (i === 0) {
        this.$router.push({
          path: `/detail-page/乌镇人民公园}`,
          query: {
            bbs: 3,
          },
        });
      } else {
        // this.$router.push({
        //   path: `/detail-page/乌镇隆源路街区}`,
        //   query: {
        //     bbs: 4,
        //   },
        // });
      }
    },
  },
  created() {
    this.win();
    this.imgUrl = this.imgArr[0];
    this.activate[0] = true;
    this.okm = 0;
    this.centre = 0;
    this.goal2 = 0;
  },
  mounted() {
    this.distance();
    for (let img of this.imgArr) {
      let image = new Image();
      image.src = img;
    }
  },
};
</script>

<style lang="scss">
.wicket {
  height: 376px;
  width: 100%;
  .vimg {
    float: left;
  }
}
.excursion {
  transition: transform 1s ease;
}
.kdu {
  width: 560px;
  padding-left: 40px;
  padding-right: 40px;
  position: absolute;
  top: 376px;
  z-index: 99999;
  .jklo {
    border-left: 1px solid;
    padding-left: 24px;
    padding-top: 24px;
  }
}
.fullBg {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  position: fixed;
  opacity: 0;
  transition: transform 0.5s, opacity 0.5s;
  transform: scale(1);
  z-index: 1;
}
.fullBg2 {
  opacity: 1;
  transform: scale(1.02);
}
.hjj {
  opacity: 1;
  transform: scale(1.02);
}
.jzbj-case {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: 100px;
}
</style>