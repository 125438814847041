<template>
	<div class="box">
		<!-- :style="{ backgroundImage: `url(${backdrop})` }" -->
		<ul>
			<li
					v-for="(item, index) in items"
					:key="index"
					:style="{
          width: item.state ? contentWidth + 1 + 'px' : '116px',
        }"
					@mouseover="enter(item)"
					@mouseout="leave(item, $event)"
			>
				<!-- <v-img :src="item.url" height="100%" class="breadth">
					<p>{{ item.title }}</p>
        </v-img> -->
				<!-- <img :src="item.url" alt="" >
				<p>{{ item.title }}</p> -->
				<!-- <v-hover v-slot="{ hover }"> -->
				<!-- <div
						:style="{ backgroundImage: `url(${(hover == true)? item.url:item.header})` }"
						class="bjt"
					></div> -->
				<div
						:style="{ backgroundImage: `url(${item.header})`, width: '116px' }"
						class="bjt taig"
						v-show="!item.state"
				>
					<div
							class="jzbj"
							style="
              font-size: 36px;
              font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
              font-weight: bold;
              color: #ffffff;
              line-height: 47px;
              text-align: center;
            "
					>
						{{ item.title }}
					</div>
				</div>
				<div
						:style="{
            backgroundImage: `url(${item.url})`,
            // width: item.state === true ? contentWidth + 1 + 'px' : '116px',
          }"
						class="bjt"
						v-show="item.state"
				>
					<div
							:style="{
              width: '100%',
              height: '100%',
              paddingTop: `${height - 110}px`,
            }"
					>
						<v-expand-transition>
							<div
									class="d-flex transition-fast-in-fast-out v-card--reveal white--text"
									style="
                  height: 100%;
                  padding: 0px 60px;
                  width: 100%;
                  background-color: rgba(0, 0, 0, 0.4);
                "
							>
								<div
										style="
                    display: block;
                    width: 100%;
                    margin-top: 24px;
                    margin-bottom: 24px;
                    line-height: 50px;
                    position: relative;
                  "
								>
									<strong
											style="font-size: 36px; font-weight: bold; bottom: 10px"
											class="taig"
									>
										{{ item.title }}
									</strong>
									<div
											style="
                      width: 2px;
                      height: 48px;
                      background: #ffffff;
                      display: inline-block;
                      margin: 6px 60px 0px;
                    "
									></div>
									<div
											class="czjz"
											style="
                      font-size: 20px;
                      font-family: MicrosoftYaHei;
                      display: inline-block;
                      width: 52.37%;
                      line-height: 30px;
                    "
									>{{ item.content }}
									</div
									>

									<v-btn
											outlined
											rounded
											color="white"
											height="48px"
											width="128px"
											style="margin-top: 7px; float: right"
											class="taig"
											@click="route(index)"
									>
										了解更多
									</v-btn>
								</div>
							</div>
						</v-expand-transition>
					</div>
				</div>
				<!-- </v-hover> -->
			</li>
		</ul>
	</div>
</template>

<script>
import group from "@/views/detail-page/data.js";

export default {
	data() {
		return {
			items: [
				{
					header: require("@/assets/img/product/breviary1.jpg"),
					title: "数景云平台",
					content:
							"数字化景区管理云平台（简称数景中台）主要功能是接收渠道订单，分发给景区本地客房、餐饮、门票等子系统。通过综合预定页面，对客房...",
					url: require("@/assets/img/product/advocate1.jpg"),
					state: false,
				},
				{
					header: require("@/assets/img/product/breviary2.jpg"),
					title: "智能化设备管理平台",
					content:
							"随着5G、新基建时代的到来，人们希望打造一个AI沉浸式服务体验的未来科技园，管理中心平台是公园大脑，AI机器⼈家族是服务终端，为市民打造...",
					url: require("@/assets/img/product/advocate2.jpg"),
					state: false,
				},
				{
					header: require("@/assets/img/product/breviary3.jpg"),
					title: "AI智能路灯",
					content:
							"公园AI安全及服务机器⼈，拥有智能监控、感应照明、信息发布等功能。",
					url: require("@/assets/img/product/advocate3.jpg"),
					state: false,
				},
				{
					header: require("@/assets/img/product/breviary4.png"),
					title: "旅游目的地一体化建站",
					content: "个性化定制目的地小程序,旅游景区酒店民宿,票务,套餐业态全支持",
					url: require("@/assets/img/product/advocate4.png"),
					state: false,
				},
			],
			width: "",
			height: "",
			contentWidth: "",
			last: true,
			weizhi: 0,
			backdrop: "",
			formerly: ""
		};
	},
	methods: {
		win() {
			this.width = window.innerWidth + "px";
			this.height = window.innerHeight;
			let len = this.items.length - 1;
			this.width = parseInt(this.width);
			this.contentWidth = this.width - len * 116 - 8.5;
		},
		enter(obj) {
			if (obj.state !== true && this.last === true) {
				this.last = false;
				this.items[this.weizhi].state = false;
				obj.state = true;
			} else {
				obj.state = true;
			}
		},
		leave(obj, e) {
			if (
					e.relatedTarget == null ||
					e.relatedTarget.nodeName === "HTML" ||
					e.clientY <= 119
			) {
			} else {
				let arr = [
					"v-carousel__controls",
					"v-item-group theme--dark",
					"v-btn__content",
				];
				if (arr.indexOf(e.relatedTarget.className) === -1) {
					this.formerly = obj.url;
					obj.state = false;
				}
			}
		},
		route(n) {
			let s = "";
			switch (n) {
				case 0:
					s = 1;
					break;
				case 1:
					s = 2;
					break;
				case 2:
					s = 0;
					break;
				default:
					break;
			}
			this.$router.push({
				path: "/detail-page/" + group[s].headline,
				query: {
					bbs: s,
				},
			});
		},
		reset() {
			this.items[2].state = true;
			this.weizhi = 2;
			this.backdrop = this.items[2].url;
		},
	},
	watch: {
		njj(v) {
			console.log(v);
		},
		formerly(val, oval) {
			val;
			if (oval) {
				this.backdrop = oval;
			}
		},
		items(val) {
			console.log(val);
		},
	},
	created() {
		this.reset();
	},
	mounted() {
		this.win();
		for (let img of this.items) {
			let image = new Image();
			image.src = img.url;
		}
	},
};
</script>

<style lang="scss">
.box {
	display: flex;
	align-items: center;
	height: 100vh;

	ul {
		width: 100%;
		height: 100%;
		overflow: hidden;
		transition: all 1s;
		padding-left: 0px;
		background-size: 100% 100%;
		background-repeat: no-repeat;

		.breadth {
			width: 116px;
		}

		li {
			position: relative;
			float: left;
			list-style-type: none;
			width: 116px;
			height: 100%;
			transition: width 0.5s;

			p {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 30px;
				text-align: center;
				font-size: 14px;
				font-weight: 700;
				color: #fff;
				background-color: rgba(0, 0, 0, 0.3);
				transition: all 0.3s;
			}

			.bjt {
				background-size: 100% 100%;
				background-repeat: no-repeat;
				width: 100%;
				height: 100%;
				transition: width 0.5s;
			}
		}
	}
}

.taig {
	position: relative;
}
</style>
