<template>
  <div class="carouselimg">
    <v-carousel
      v-model="modelapp"
      height="100vh"
      vertical-delimiters
      vertical
      hide-delimiter-background
      ref="lbt"
    >
      <v-carousel-item
        v-for="(item, index) in items"
        :key="index"
        height="100vh"
        active-class="magnify--active"
      >
        <template v-if="index === 2">
          <div>
            <productRecommend ref="vbb" />
          </div>
        </template>
        <template v-else-if="index === 3">
          <div>
            <customerCase />
          </div>
        </template>
        <template v-else>
          <v-img
            :src="require('@/assets/img/home/' + item + '.png')"
            height="100vh"
            :class="{ 'manify-img': true }"
          >
          </v-img>

          <v-overlay
            :value="true"
            :opacity="index == 1 || index == 4 ? 0.3 : 0"
          >
            <keep-alive>
              <component v-bind:is="currentView[index]"></component>
            </keep-alive>
          </v-overlay>
        </template>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import customerCase from "@/views/customer-case/customer-case.vue";
import productRecommend from "@/views/product-recommend/product-recommend.vue";
import caption0 from "@/components/home-caption0/home-caption0.vue"
import caption1 from "@/components/home-caption1/home-caption1.vue"
import caption4 from "@/components/home-caption4/home-caption4.vue"
import caption5 from "@/components/home-caption5/home-caption5.vue"
export default {
  components: {
    customerCase,
    productRecommend,
    caption0,
    caption1,
    caption4,
    caption5
  },
  props: {
    skip: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      modelapp: 0,
      items: ["banner", "gywm", "", "", "hzhb", "jrwm"],
      currentView: {
        0: "caption0",
        1: "caption1",
        4: "caption4",
        5: "caption5"
      }
    };
  },
  methods: {
    ...mapMutations(["topHoverFun", "bottomHoverFun", "navigationFun"]),
    myFunction(v) {
      if (v.deltaY > 0) {
        this.modelapp += this.modelapp !== this.items.length - 1 ? 1 : 0;
      } else {
        this.modelapp -= this.modelapp !== 0 ? 1 : 0;
      }
      this.selectTab(this.modelapp);
    },
    selectTab(index) {
      let $tabRef = this.$parent.$parent.$children[0].$children[0].$refs['tabRef'];
      $tabRef[index].$el.click();
    }
  },
  computed: {
    ...mapGetters(["skipget"]),
  },
  watch: {
    modelapp(val) {
      if (val > 0) {
        this.topHoverFun(false);
        this.navigationFun(false);
      } else {
        this.topHoverFun(true);
        this.navigationFun(true);
      }
      if (val == this.items.length - 1) {
        this.bottomHoverFun(true);
      } else {
        this.bottomHoverFun(false);
      }
      if (val == 2) {
        let vbb = this.$refs["vbb"];
        if (vbb) {
          vbb[0].reset();
        }
      }
    },
    skip(v) {
      console.log(v);
    },
    "$store.state.skip": {
      handler: function (v) {
        this.modelapp = v;
      },
    },
  },
  mounted() {
    this.$refs.lbt.$el.addEventListener("wheel", this.myFunction);
    let index = sessionStorage.getItem("url");
    if (index) {
      this.modelapp = parseInt(index);
      this.selectTab(index);
    }
    let imgs = [...this.items.slice(1,2),...this.items.slice(-2)];
    for (let img of imgs) {
      let image = new Image();
      image.src = require('@/assets/img/home/' + img + '.png');
    }
  },
};
</script>

<style>
</style>