import axios from 'axios'
// 创建axios 实例
const service = axios.create({
    timeout: 30000, // 请求超时设置
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    }
});

// 添加响应拦截器
service.interceptors.response.use(
    response => {
        // 对一些可以全局处理的错误进行默认处理
        const res = response.data;
        // 接口状态码不是200成功
        console.log(res)
        return res;
    },
    error => {
        return Promise.reject(error)
    }
);

export default service