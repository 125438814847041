import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import store from '@/store/index.js';

import axios from '@/utils/request.js'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios);


import '@/assets/icon/iconfont.css'
import '@/assets/scss/style.scss'

Vue.config.productionTip = false

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
