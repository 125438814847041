<template>
  <div
    style="
      width: 82.5%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    "
  >
    <v-row>
      <v-col cols="12" sm="3" align-self="center">
        <v-icon
          style="font-size: 37px; position: relative; right: 67.5px"
          color="white"
          v-if="color"
          ref="logo"
        >
          iconfont icon-chengwanlogo2
        </v-icon>
        <v-img
          v-else
          src="@/assets/img/navigation/logo1.svg"
          max-height="37"
          max-width="67.5"
          ref="logo"
        ></v-img>
      </v-col>
      <v-col cols="12" sm="6" align-self="center">
        <v-tabs
          center-active
          grow
          background-color="transparent"
          color="#2CA6E0"
          class="baise"
          :class="{ blame: !color }"
          v-model="Control"
          @change="changefun"
        >
          <v-tab
            v-for="(item, index) in items"
            :key="index"
            ref="tabRef"
            @click="particularly"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="12" sm="3" align-self="center">
        <v-hover v-slot="{ hover }">
          <div
            style="margin-left: auto; padding-top: 26px"
            class="sbys"
            :class="{ 'on-hover': hover }"
          >
            <v-text-field
              dense
              label="智能化设备管理平台"
              color="#333333"
              rounded
              append-icon="iconfont icon-sousuo"
              class="shurk"
              single-line
              outlined
              @click:append="tuBiao"
              v-model="search"
            >
            </v-text-field>
          </div>
        </v-hover>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import group from "@/views/detail-page/data.js";
import { mapMutations } from "vuex";
export default {
  props: {
    color: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    iconColor() {
      return this.color == true ? "white" : "#FF1B79B2";
    },
  },
  data() {
    return {
      Control: 0,
      items: [
        "首页",
        "关于我们",
        "产品介绍",
        "客户案例",
        "合作伙伴",
        "加入我们",
      ],
      nonuse: [""],
      urlitems: [
        "/home",
        "/About-Us",
        "/product-recommend",
        "/customer-case",
        "/cooperative-partner",
        "/join-us",
      ],
      urlitems2: ["/join-us", "/cooperative-partner", "/About-Us"],
      tmr: false,
      search: "",
      searchItems: [],
    };
  },
  methods: {
    ...mapMutations(["skipFun", "topHoverFun"]),
    fetchData() {
    },
    tuBiao() {
      if (this.search) {
        this.$router.push({
          path: "/search",
          query: {
            keyword: this.search,
          },
        });
        this.$emit("callback", true);
      }
    },
    mouseoutFun() {
    },
    particularly() {
    },
    changefun(val) {
      if (
        this.$route.meta.name == "详情页" ||
        this.$route.meta.name == "搜索结果页面"
      ) {
        sessionStorage.setItem("url", val);
        this.$router.push("/home");
      } else {
        this.skipFun(val);
      }
    },
    mbb() {
      console.log(456);
    },
  },
  watch: {
    $route: {
      handler: function (v) {
        if (v.meta.name == "详情页" && this.Control != 2) {
        }
      },
    },
  },
  mounted() {
    this.searchItems = group;
    if (this.$route.path != "/home") {
      this.$emit("callback", true);
    }
  },
};
</script>

<style lang="scss">
.jhi {
  width: 412px;
  display: inline-block;
}
.sbys {
  width: 60px;
  transition: width 0.5s 1s;
}
</style>