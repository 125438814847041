<template>
	<div class="spjz" style="text-align: center;top:-261px;width:800px;">
		<div
				style="
        font-size: 14px;
        font-family: MicrosoftYaHeiLight;
        line-height: 19px;
        letter-spacing: 8px;
      "
		>
			DIGITAL SCENIC ECOLOGY
		</div>
		<p
				style="
        font-weight: bold;
        font-size: 56px;
        line-height: 74px;
        letter-spacing: 12px;
        margin-top: 20px;
        margin-bottom: 20px;
      "
		>
			旅游目的地一体化建站
		</p>
		<div
				style="
        font-size: 26px;
        font-family: MicrosoftYaHei;
        line-height: 35px;
        letter-spacing: 8px;
      "
		>
			支持Web和小程序的快速搭建
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style>
</style>
