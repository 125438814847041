var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('ul',_vm._l((_vm.items),function(item,index){return _c('li',{key:index,style:({
          width: item.state ? _vm.contentWidth + 1 + 'px' : '116px',
        }),on:{"mouseover":function($event){return _vm.enter(item)},"mouseout":function($event){return _vm.leave(item, $event)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!item.state),expression:"!item.state"}],staticClass:"bjt taig",style:({ backgroundImage: ("url(" + (item.header) + ")"), width: '116px' })},[_c('div',{staticClass:"jzbj",staticStyle:{"font-size":"36px","font-family":"MicrosoftYaHei-Bold, MicrosoftYaHei","font-weight":"bold","color":"#ffffff","line-height":"47px","text-align":"center"}},[_vm._v(" "+_vm._s(item.title)+" ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.state),expression:"item.state"}],staticClass:"bjt",style:({
            backgroundImage: ("url(" + (item.url) + ")"),
            // width: item.state === true ? contentWidth + 1 + 'px' : '116px',
          })},[_c('div',{style:({
              width: '100%',
              height: '100%',
              paddingTop: ((_vm.height - 110) + "px"),
            })},[_c('v-expand-transition',[_c('div',{staticClass:"d-flex transition-fast-in-fast-out v-card--reveal white--text",staticStyle:{"height":"100%","padding":"0px 60px","width":"100%","background-color":"rgba(0, 0, 0, 0.4)"}},[_c('div',{staticStyle:{"display":"block","width":"100%","margin-top":"24px","margin-bottom":"24px","line-height":"50px","position":"relative"}},[_c('strong',{staticClass:"taig",staticStyle:{"font-size":"36px","font-weight":"bold","bottom":"10px"}},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticStyle:{"width":"2px","height":"48px","background":"#ffffff","display":"inline-block","margin":"6px 60px 0px"}}),_c('div',{staticClass:"czjz",staticStyle:{"font-size":"20px","font-family":"MicrosoftYaHei","display":"inline-block","width":"52.37%","line-height":"30px"}},[_vm._v(_vm._s(item.content)+" ")]),_c('v-btn',{staticClass:"taig",staticStyle:{"margin-top":"7px","float":"right"},attrs:{"outlined":"","rounded":"","color":"white","height":"48px","width":"128px"},on:{"click":function($event){return _vm.route(index)}}},[_vm._v(" 了解更多 ")])],1)])])],1)])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }