<template>
  <div class="jzbj" style="width:1008px;">
    <div style="text-align: center">
      <div
        style="
          font-size: 56px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          line-height: 74px;
        "
      >
        合作伙伴
      </div>
      <div
        style="
          font-size: 36px;
          font-family: MicrosoftYaHeiLight;
          line-height: 48px;
        "
      >
        Partner
      </div>
    </div>
    <div class="hnnn">
      <img
        :src="url"
        alt=""
        v-for="(url, index) in items"
        :key="index"
        width="248"
        height="124"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        require("@/assets/img/home/中景艺.jpg"),
        require("@/assets/img/home/云闪住.jpg"),
        require("@/assets/img/home/商汤.jpg"),
        require("@/assets/img/home/甲板.jpg"),
        require("@/assets/img/home/携程.jpg"),
        require("@/assets/img/home/飞猪.jpg"),
      ],
    };
  },
};
</script>

<style>
</style>