<template>
  <div class="jzbj" style="width:720px;">
    <div
      style="
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        line-height: 74px;
        font-size: 56px;
        text-align: center;
      "
    >
      关于我们
    </div>
    <div
      style="
        font-size: 36px;
        font-family: MicrosoftYaHeiLight;
        line-height: 48px;
        text-align: center;
        margin-bottom: 40px;
      "
    >
      ABOUT US
    </div>
    <p
      style="
        font-size: 18px;
        font-family: MicrosoftYaHei;
        line-height: 28px;
        margin-bottom: 36px;
      "
    >
      北海城湾科技发展有限公司-简称“城湾科技”，是一家集品牌策略规划设计、生态场景营造、智慧化系统研发于一体的高新科技企业。
    </p>
    <p style="font-size: 18px; font-family: MicrosoftYaHei; line-height: 28px">
      城湾科技专注于景区、城市、园林等综合一体化的数字平台的研发，以及公共空间的智慧化场景应用整体解决方案的提供商、服务商。公司凝聚了一群敢于创新、敢于钻研的年轻人，核心团体由国内顶级学府、科研院校、海外留学学子组成。以匠心精神打磨每一个产品是公司的追求和服务宗旨。
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>